<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <router-link to="/"></router-link>
            <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
            <router-view></router-view>
        </div>
    </a-config-provider>
</template>


<script>
// import HelloWorld from "./components/HelloWorld.vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
export default {
    name: "App",
    components: {},
    data() {
        return {
            locale: zhCN,
            current: [],
			url: "/scrm_pc",
			fullPath: "",
            navOn:[0],
        };
    },
    watch: {
        $route(val) {
            if (val.fullPath) {
                let fullpath = val.fullPath;
                let parmes = "";
                if(fullpath != "/scrm_wap/client/clientDetail"){
                    if (this.isMobile()) {
                        if (fullpath.indexOf("/scrm_wap") == -1) {
                            parmes = this.getUrl("/scrm_wap", fullpath);
                        }
                    } else {
                        if (fullpath.indexOf("/scrm_pc") == -1) {
                            parmes = this.getUrl("/scrm_pc", fullpath);
                        }
                    }
                }else{
                    parmes = fullpath;
                }

                if (parmes) {
                    this.$router.push(parmes);
                }
            }
        },
    },
    mounted() {
        this.$message.config({
            top: `100px`,
            duration: 2,
            maxCount: 3,
        });
        // if (this.isMobile()) {
        // 	// alert("手机端");
        // 	this.$router.replace('/scrm_wap');
        // } else {
        // 	// alert("pc端");
        // 	this.$router.replace('/scrm_pc');
        // }
    },
    methods: {
        getUrl(keyUrl, fullpath) {
            console.log(keyUrl, fullpath)
            if (fullpath.indexOf("scrm_wap") != -1) {
                if (fullpath.split("scrm_wap")[1]) {
                    keyUrl = keyUrl + fullpath.split("scrm_wap")[1];
                }
                console.log("dangqianshoujidaun", keyUrl);
            } else {
                console.log(fullpath.split("scrm_pc")[1])
                keyUrl = keyUrl + fullpath.split("scrm_pc")[1];
                console.log("dangqinapcduan", keyUrl);
            }
            return keyUrl;
        },
        isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        },
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    font-size: 16px;
    overflow: scroll;
    height: 100vh;
}
.ant-table-thead .ant-table-column-title{
    font-weight: bold;
}
.global_head_title{
    text-align: left;
    font-weight: bold;
}
/* .content .content_row .info {
   display: inline-block;
   width: 15rem;
   
   padding-right: 1rem;
   color: #202020;
   text-align: right;
   text-align-last: justify;
   font-weight: bold!important;
} */
  /* 输入框 下拉框 */
 /*.content .content_row input,.content .content_row .ant-select{
    width: 100%; 
    background-color: #fff!important;
}*/
.ant-select-selection{
    background-color: #fff!important;
}
.redS{color: red;}
</style>
