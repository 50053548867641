import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: '/scrm_pc',
    },
    {
        path: '/scrm_pc',
        redirect: '/scrm_pc/home',
        component: () =>
            import ('../pages/scrm_pc/index'),
        children: [{
                path: 'task',
                redirect: '/scrm_pc/task/taskAllList',
                component: () =>
                    import ('../pages/scrm_pc/task'),
                children: [{
                        path: 'taskAllList',
                        name: 'taskAllList',
                        meta: {
                            title: '全部任务'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/task/taskAllList')
                    }, {
                        path: 'taskList',
                        name: 'taskList',
                        meta: {
                            title: '我新建的任务'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/task/taskList')
                    },
                    {
                        path: 'taskList2',
                        meta: {
                            title: '我待办的任务'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/task/taskList2')
                    },
                    {
                        path: 'taskAdd',
                        meta: {
                            title: '新建任务'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/task/taskAdd')
                    },
                ]
            },
            {
                path: 'order',
                redirect: '/scrm_pc/order/orderList',
                component: () =>
                    import ('../pages/scrm_pc/order'),
                children: [{
                        path: 'orderList',
                        name: 'orderList',
                        meta: {
                            title: '我的合同',
                            keepAlive: true,
                        },
                        component: () => import ('../pages/scrm_pc/order/orderList'),
                    },

                    {
                        path: 'orderAllList',
                        name: 'orderAllList',
                        meta: {
                            title: '全部合同'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/orderAllList')
                    },
                    {
                        path: 'orderAdd',
                        meta: {
                            title: '新建合同'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/orderAdd')
                    },
                    {
                        path: 'paymentList',
                        meta: {
                            title: '回款列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/paymentList')
                    },
                    {
                        path: 'paymentListBranch',
                        meta: {
                            title: '部门回款列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/paymentListBranch')
                    },
                    {
                        path: 'refundList',
                        meta: {
                            title: '退款列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/refundList')
                    },
                    {
                        path: 'invoiceList',
                        meta: {
                            title: '全部发票'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/invoiceList')
                    },
                    {
                        path: 'invoiceSignList',
                        meta: {
                            title: '我领取的发票'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/invoiceSignList')
                    },
                    {
                        path: 'invoiceCreaterList',
                        meta: {
                            title: '我创建的发票'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/invoiceCreaterList')
                    },
                    {
                        path: 'invoiceAdd',
                        meta: {
                            title: '添加发票'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/order/invoiceAdd')
                    },
                ]
            },
            {
                path: 'client',
                redirect: '/scrm_pc/client/clientMy',
                component: () =>
                    import ('../pages/scrm_pc/client'),
                children: [{
                        path: 'clientList',
                        name: 'clientList',
                        meta: {
                            title: '全部客户'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/clientList')
                    },
                    {
                        path: 'clientMy',
                        meta: {
                            title: '我的客户'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/clientMy')
                    },
                    {
                        path: 'linkmanAll',
                        meta: {
                            title: '全部联系人'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/linkmanAll')
                    },
                    {
                        path: 'linkmanMy',
                        meta: {
                            title: '我的联系人'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/client/linkmanMy')
                    },
                ]
            },
            {
                path: 'datas',
                redirect: '/scrm_pc/datas/client',
                component: () =>
                    import ('../pages/scrm_pc/datas'),
                children: [{
                        path: 'daily',
                        meta: {
                            title: '经营日报'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/datas/daily')
                    },
                    {
                        path: 'client',
                        meta: {
                            title: '客户分析'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/datas/client')
                    },
                    {
                        path: 'order',
                        meta: {
                            title: '合同分析'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/datas/order')
                    },
                    {
                        path: 'goal',
                        meta: {
                            title: '目标分析'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/datas/goal')
                    },
                ]
            },
            {
                path: 'target',
                redirect: '/scrm_pc/target/my',
                component: () =>
                    import ('../pages/scrm_pc/target'),
                children: [

                    {
                        path: 'my',
                        meta: {
                            title: '个人目标'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/target/my'),
                    },
                    // {
                    //     path: 'show',
                    //     name: 'show',
                    //     meta: {
                    //         title: '目标展示'
                    //     },
                    //     component: () =>
                    //         import ('../pages/scrm_pc/target/show')
                    // },
                    {
                        path: 'group',
                        meta: {
                            title: '团队目标'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/target/group')
                    },
                    {
                        path: 'add',
                        meta: {
                            title: '新建个人目标'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/target/add')
                    },
                    {
                        path: 'addgroup',
                        meta: {
                            title: '新建团队目标'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/target/addgroup')
                    },
                ]
            },

            {
                path: 'set',
                redirect: '/scrm_pc/set/staffList',
                component: () =>
                    import ('../pages/scrm_pc/set'),
                children: [{
                        path: 'staffList',
                        name: 'staffList',
                        meta: {
                            title: '员工列表'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/staffList')
                    },
                    {
                        path: 'set',
                        meta: {
                            title: '客户字段设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/set')
                    },
                    {
                        path: 'tagSet',
                        meta: {
                            title: '标签设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/tagSet')
                    },
                    {
                        path: 'orderSet',
                        meta: {
                            title: '合同字段设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/orderSet')
                    },
                    {
                        path: 'linkmanSet',
                        meta: {
                            title: '联系人字段设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/linkmanSet')
                    },
                    {
                        path: 'saleSet',
                        meta: {
                            title: '客户跟进设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/saleSet')
                    },
                    {
                        path: 'roleSet',
                        meta: {
                            title: '角色设置'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/set/roleSet')
                    },
                    {
                        path: 'goodsList',
                        meta: {
                            title: '产品/服务管理',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/goods/goodsList')
                    },
                    {
                        path: 'goodsAdd',
                        meta: {
                            title: '添加分类'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/goods/goodsAdd')
                    },
                    {
                        path: 'goodsType',
                        meta: {
                            title: '产品/服务分类',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/goods/goodsType')
                    },
                    {
                        path: 'miniAppSet',
                        meta: {
                            title: '小程序设置',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/miniApp/miniAppSet')
                    },
                    {
                        path: 'carouselList',
                        meta: {
                            title: '小程序轮播图'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/miniApp/carouselList')
                    },
                ]
            },
            {
                path: 'menu',
                meta: {
                    title: '首页'
                },
                component: () =>
                    import ('../pages/scrm_pc/home'),

            },
            {
                path: 'home',
                meta: {
                    title: '首页'
                },
                component: () =>
                    import ('../pages/scrm_pc/home')
            },
            {
                path: 'task/taskDetail',
                meta: {
                    title: '任务详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/task/taskDetail')
            },
            {
                path: 'order/orderDetail',
                meta: {
                    title: '合同详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/order/orderDetail')
            },
            {
                path: 'target/detail',
                meta: {
                    title: '目标详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/target/detail')
            },
            {
                path: 'target/groupdetail',
                meta: {
                    title: '团体目标详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/target/groupdetail')
            },
            {
                path: 'worker',
                meta: {
                    title: '服务人员'
                },
                redirect: '/scrm_pc/worker/workersMy',
                component: () =>
                    import ('../pages/scrm_pc/worker/index'),
                children: [

                    {
                        path: 'workerList',
                        meta: {
                            title: '服务人员列表',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/workerList')
                    },
                    {
                        path: 'workersMy',
                        meta: {
                            title: '我的服务人员列表',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/workersMy')
                    },
                    {
                        path: 'workerAdd',
                        meta: {
                            title: '服务人员添加',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/workerAdd')
                    },
                    {
                        path: 'operatList',
                        meta: {
                            title: '工作点管理',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/operatList')
                    },
                    {
                        path: 'operatMy',
                        meta: {
                            title: '我的工作点管理',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/operatMy')
                    },
                    {
                        path: 'operatAdd',
                        meta: {
                            title: '工作点添加',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/operatAdd')
                    },
                    {
                        path: 'workerSet',
                        meta: {
                            title: '工作点设置',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/workerSet')
                    },
                    {
                        path: 'clotype',
                        meta: {
                            title: '工装类型',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/clotype')
                    },
                    {
                        path: 'workerPay',
                        meta: {
                            title: '服务人员薪资',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/workerPay')
                    },
                    {
                        path: 'workerPayAdd',
                        meta: {
                            title: '薪资录入',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/worker/workerPayAdd')
                    },
                ]
            },
            {
                path: 'worker/workerDetail',
                meta: {
                    title: '服务人员详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/worker/workerDetail')
            },

            {
                path: 'clue',
                meta: {
                    title: '线索'
                },
                redirect: '/scrm_pc/clue/clueList',
                component: () =>
                    import ('../pages/scrm_pc/clue/index'),
                children: [{
                        path: 'clueList',
                        meta: {
                            title: '线索列表',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/clue/clueList')
                    },

                ]
            },
            {
                path: 'temp',
                meta: {
                    title: '工人'
                },
                redirect: '/scrm_pc/temp/tempList',
                component: () =>
                    import ('../pages/scrm_pc/temp/index'),
                children: [

                    {
                        path: 'tempList',
                        meta: {
                            title: '工人列表',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/temp/tempList'),

                    },
                    {
                        path: 'tempAdd',
                        meta: {
                            title: '工人添加'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/temp/tempAdd')
                    },
                    {
                        path: 'tempType',
                        meta: {
                            title: '工人技能',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/temp/tempType'),
                    },
                    {
                        path: 'tempCheck',
                        meta: {
                            title: '工人考勤'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/temp/tempCheck')
                    },
                    {
                        path: 'itemList',
                        meta: {
                            title: '项目列表',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/item/itemList'),
                    },
                    {
                        path: 'itemSet',
                        meta: {
                            title: '项目设置',
                        },
                        component: () =>
                            import ('../pages/scrm_pc/item/itemSet'),
                    },
                    {
                        path: 'itemAdd',
                        meta: {
                            title: '项目添加'
                        },
                        component: () =>
                            import ('../pages/scrm_pc/item/itemAdd')
                    },
                ]
            },
            {
                path: 'item',
                meta: {
                    title: '项目'
                },
                redirect: '/scrm_pc/item/itemList',
                component: () =>
                    import ('../pages/scrm_pc/item/index'),
                children: [

                ]
            },
            {
                path: 'temp/tempDetail',
                meta: {
                    title: '工人详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/temp/tempDetail')
            },
            {
                path: 'item/itemDetail',
                meta: {
                    title: '项目详情'
                },
                component: () =>
                    import ('../pages/scrm_pc/item/itemDetail')
            },
            {
                path: 'clue/clueEdit',
                meta: {
                    title: '线索编辑'
                },
                component: () =>
                    import ('../pages/scrm_pc/clue/clueEdit')
            },
        ]
    },

    // {
    //     path: '/scrm_wap',
    //     redirect:'/scrm_wap/index',

    // },
    // {   
    //     path: '/scrm_wap/index',
    //     component: () => import('../pages/scrm_wap/index')
    // },


    {
        path: '/scrm_wap',
        redirect: '/scrm_wap/home',
        component: () =>
            import ('../pages/scrm_wap'),
        children: [

            {
                path: 'index',
                redirect: '/scrm_wap/home',
                // meta: {
                //     title: ''
                // },
                component: () =>
                    import ('../pages/scrm_wap')
            },
            {
                path: 'home',
                // meta: {
                //     title: '首页'
                // },
                component: () =>
                    import ('../pages/scrm_wap/home')
            },
            {
                path: 'menu',
                // meta: {
                //     title: '功能'
                // },
                component: () =>
                    import ('../pages/scrm_wap/menu'),

            },
            {
                path: 'news',
                // meta: {
                //     title: '消息'
                // },
                component: () =>
                    import ('../pages/scrm_wap/news')
            },
            {
                path: 'set/index',
                // meta: {
                //     title: '设置'
                // },
                component: () =>
                    import ('../pages/scrm_wap/set/index')
            },
            {
                path: '/scrm_wap/client/addClient',
                meta: {
                    title: '添加客户'
                },
                component: () =>
                    import ('../pages/scrm_wap/client/addClient')
            },
        ]
    },
    {
        path: '/scrm_wap/target/add',
        meta: {
            title: '新建目标'
        },
        component: () =>
            import ('../pages/scrm_wap/target/add')
    },
    {
        path: '/scrm_wap/target/my',
        meta: {
            title: '我的目标'
        },
        component: () =>
            import ('../pages/scrm_wap/target/my'),

    },
    // {
    //     path: '/scrm_wap/target/show',
    //     meta: {
    //         title: '目标展示'
    //     },
    //     component: () =>
    //         import ('../pages/scrm_wap/target/show')
    // },
    {
        path: '/scrm_wap/target/group',
        meta: {
            title: '团队目标'
        },
        component: () =>
            import ('../pages/scrm_wap/target/group')
    },
    {
        path: '/scrm_wap/client/clientList',
        meta: {
            title: '全部客户'
        },
        component: () =>
            import ('../pages/scrm_wap/client/clientList')
    },
    {
        path: '/scrm_wap/client/clientMy',
        meta: {
            title: '我的客户'
        },
        component: () =>
            import ('../pages/scrm_wap/client/clientMy')
    },
    {
        path: '/scrm_wap/client/linkmanAll',
        meta: {
            title: '全部联系人'
        },
        component: () =>
            import ('../pages/scrm_wap/client/linkmanAll')
    },
    {
        path: '/scrm_wap/client/linkmanMy',
        meta: {
            title: '我的联系人'
        },
        component: () =>
            import ('../pages/scrm_wap/client/linkmanMy')
    },
    {
        path: '/scrm_wap/client/clientDetail',
        meta: {
            title: '客户详情'
        },
        component: () =>
            import ('../pages/scrm_wap/client/clientDetail')
    },
    {
        path: '/scrm_wap/client/addLinkman',
        meta: {
            title: '添加联系人'
        },
        component: () =>
            import ('../pages/scrm_wap/client/addLinkman')
    },
    {
        path: '/scrm_wap/task/taskAllList',
        meta: {
            title: '全部任务'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskAllList')
    },
    {
        path: '/scrm_wap/task/taskList',
        meta: {
            title: '我的新建'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskList')
    },
    {
        path: '/scrm_wap/task/taskList2',
        meta: {
            title: '我的待办'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskList2')
    },
    {
        path: '/scrm_wap/task/taskAdd',
        meta: {
            title: '添加任务'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskAdd')
    },
    {
        path: '/scrm_wap/task/taskDetail',
        meta: {
            title: '任务详情'
        },
        component: () =>
            import ('../pages/scrm_wap/task/taskDetail')
    },

    {
        path: '/scrm_wap/order/orderAllList',
        meta: {
            title: '全部合同'
        },
        component: () =>
            import ('../pages/scrm_wap/order/orderAllList')
    },
    {
        path: '/scrm_wap/order/orderList',
        meta: {
            title: '我的合同'
        },
        component: () =>
            import ('../pages/scrm_wap/order/orderList')
    },
    {
        path: '/scrm_wap/order/orderAdd',
        meta: {
            title: '添加合同'
        },
        component: () =>
            import ('../pages/scrm_wap/order/orderAdd')
    },
    {
        path: '/scrm_wap/order/orderDetail',
        meta: {
            title: '合同详情'
        },
        component: () =>
            import ('../pages/scrm_wap/order/orderDetail')
    },
    {
        path: '/scrm_wap/order/paymentList',
        meta: {
            title: '回款列表'
        },
        component: () =>
            import ('../pages/scrm_wap/order/paymentList')
    },
    {
        path: '/scrm_wap/order/paymentListBranch',
        meta: {
            title: '回款列表'
        },
        component: () =>
            import ('../pages/scrm_wap/order/paymentListBranch')
    },
    {
        path: '/scrm_wap/order/refundList',
        meta: {
            title: '退款列表'
        },
        component: () =>
            import ('../pages/scrm_wap/order/refundList')
    },
    {
        path: '/scrm_wap/order/invoiceList',
        meta: {
            title: '全部发票'
        },
        component: () =>
            import ('../pages/scrm_wap/order/invoiceList')
    },
    {
        path: '/scrm_wap/order/invoiceSignList',
        meta: {
            title: '我领取的发票'
        },
        component: () =>
            import ('../pages/scrm_wap/order/invoiceSignList')
    },
    {
        path: '/scrm_wap/order/invoiceCreaterList',
        meta: {
            title: '我创建的发票'
        },
        component: () =>
            import ('../pages/scrm_wap/order/invoiceCreaterList')
    },
    {
        path: '/scrm_wap/order/invoiceAdd',
        meta: {
            title: '添加发票'
        },
        component: () =>
            import ('../pages/scrm_wap/order/invoiceAdd')
    },



    {
        path: '/scrm_wap/datas/daily',
        meta: {
            title: '经营日报'
        },
        component: () =>
            import ('../pages/scrm_wap/datas/daily')
    },
    {
        path: '/scrm_wap/datas/client',
        meta: {
            title: '客户分析'
        },
        component: () =>
            import ('../pages/scrm_wap/datas/client')
    },
    {
        path: '/scrm_wap/datas/order',
        meta: {
            title: '合同分析'
        },
        component: () =>
            import ('../pages/scrm_wap/datas/order')
    },
    {
        path: '/scrm_wap/datas/goal',
        meta: {
            title: '目标分析'
        },
        component: () =>
            import ('../pages/scrm_wap/datas/goal')
    },



    {
        path: '/scrm_wap/set/staffList',
        meta: {
            title: '员工列表'
        },
        component: () =>
            import ('../pages/scrm_wap/set/staffList')
    },
    {
        path: '/scrm_wap/miniApp/carouselList',
        meta: {
            title: '轮播图列表'
        },
        component: () =>
            import ('../pages/scrm_wap/miniApp/carouselList')

    },
    {
        path: '/scrm_wap/miniApp/miniAppSet',
        meta: {
            title: '小程序设置'
        },
        component: () =>
            import ('../pages/scrm_wap/miniApp/miniAppSet')
    },
    {
        path: '/scrm_wap/staffList',
        meta: {
            title: '员工列表'
        },
        component: () =>
            import ('../pages/scrm_wap/staffList')
    },

    {
        path: '/scrm_wap/goods/goodsList',
        meta: {
            title: '产品列表'
        },
        component: () =>
            import ('../pages/scrm_wap/goods/goodsList')
    },
    {
        path: '/scrm_wap/goods/goodsAdd',
        meta: {
            title: '产品新增'
        },
        component: () =>
            import ('../pages/scrm_wap/goods/goodsAdd')
    },
    {
        path: '/scrm_wap/goods/goodsType',
        meta: {
            title: '产品分类'
        },
        component: () =>
            import ('../pages/scrm_wap/goods/goodsType')
    },
    {
        path: '/scrm_wap/clue/clueList',
        meta: {
            title: '线索列表'
        },
        component: () =>
            import ('../pages/scrm_wap/clue/clueList')
    },
    {
        path: '/scrm_wap/clue/clueEdit',
        meta: {
            title: '线索编辑'
        },
        component: () =>
            import ('../pages/scrm_wap/clue/clueEdit')
    },
    {
        path: '/scrm_wap/temp/tempList',
        meta: {
            title: '工人列表'
        },
        component: () =>
            import ('../pages/scrm_wap/temp/tempList')
    },
    {
        path: '/scrm_wap/temp/tempAdd',
        meta: {
            title: '工人编辑'
        },
        component: () =>
            import ('../pages/scrm_wap/temp/tempAdd')
    },
    {
        path: '/scrm_wap/temp/tempDetail',
        meta: {
            title: '工人回访'
        },
        component: () =>
            import ('../pages/scrm_wap/temp/tempDetail')
    },
    {
        path: '/scrm_wap/temp/tempType',
        meta: {
            title: '工人技能'
        },
        component: () =>
            import ('../pages/scrm_wap/temp/tempType')
    },
    {
        path: '/scrm_wap/temp/tempCheck',
        meta: {
            title: '工人考勤'
        },
        component: () =>
            import ('../pages/scrm_wap/temp/tempCheck')
    },
    {
        path: '/scrm_wap/item/itemList',
        meta: {
            title: '项目列表'
        },
        component: () =>
            import ('../pages/scrm_wap/item/itemList')
    },
    {
        path: '/scrm_wap/item/itemAdd',
        meta: {
            title: '项目新增'
        },
        component: () =>
            import ('../pages/scrm_wap/item/itemAdd')
    },
    {
        path: '/scrm_wap/item/itemDetail',
        meta: {
            title: '工时详情'
        },
        component: () =>
            import ('../pages/scrm_wap/item/itemDetail')
    },
    {
        path: '/scrm_wap/item/itemSet',
        meta: {
            title: '项目设置'
        },
        component: () =>
            import ('../pages/scrm_wap/item/itemSet')
    },
    {
        path: '/scrm_wap/worker/operatList',
        meta: {
            title: '工作点列表'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/operatList')
    },
    {
        path: '/scrm_wap/worker/operatMy',
        meta: {
            title: '我的工作点列表'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/operatMy')
    },
    {
        path: '/scrm_wap/worker/operatAdd',
        meta: {
            title: '工作点新增'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/operatAdd')
    },
    {
        path: '/scrm_wap/worker/workerSet',
        meta: {
            title: '工作点设置'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/workerSet')
    },
    {
        path: '/scrm_wap/worker/clotype',
        meta: {
            title: '工装类型'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/clotype')
    },
    {
        path: '/scrm_wap/worker/workerList',
        meta: {
            title: '服务人员列表'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/workerList')
    },
    {
        path: '/scrm_wap/worker/workersMy',
        meta: {
            title: '我的服务人员列表',
        },
        component: () =>
            import ('../pages/scrm_wap/worker/workersMy')
    },
    {
        path: '/scrm_wap/worker/workerAdd',
        meta: {
            title: '服务人员添加'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/workerAdd')
    },
    {
        path: '/scrm_wap/worker/workerDetail',
        meta: {
            title: '服务人员详情'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/workerDetail')
    },
    {
        path: '/scrm_wap/worker/workerPay',
        meta: {
            title: '服务人员薪资'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/workerPay')
    },
    {
        path: '/scrm_wap/worker/workerPayAdd',
        meta: {
            title: '薪资添加'
        },
        component: () =>
            import ('../pages/scrm_wap/worker/workerPayAdd')
    },
]


const router = new VueRouter({
    mode: "history",
    routes
})
router.beforeEach((to, from, next) => {
    // console.log(to, from, next);
    // to: Route: 即将要进入的目标 路由对象

    // from: Route: 当前导航正要离开的路由

    // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

    // const nextRoute = ['temp', 'editTemplate', 'plugin', 'toolList', 'templateList', 'template', 'workflow', 'workflowList', 'sysmanage', 'addWorkflow'];

    // let isLogin = true;

    // 未登录状态；当路由到nextRoute指定页时，跳转至login

    // if (nextRoute.indexOf(to.name) >= 0 && !isLogin) {
    //     next({
    //         path: '/login',
    //         // 将跳转的路由path作为参数，登录成功后跳转到该路由
    //         query: {
    //             redirect: to.fullPath
    //         }
    //     })

    // } else {
    //     next();

    // }

    // 已登录状态；当路由到login时，跳转至home

    // if (to.name === 'login') {
    //     if (isLogin) {
    //         console.log('已登录');

    //         router.push({
    //             path: '/temp/index'
    //         });

    //     }

    // }
    let path = routes.find((item) => {
        let url = to.path.split('/')[1];
        if (item.path.indexOf(url) != -1) {
            return true
        }
    })

    if (path) {
        next();
    } else {
        router.push({
            path: '/scrm_pc'
        });
    }


});
export default router