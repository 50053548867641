import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import Antd from 'ant-design-vue';
// import $ from "jquery";
import "@/assets/js/rem.js"
import "@/assets/css/reset.css"
import "@/assets/css/main.css"
import 'ant-design-vue/dist/antd.less';
import utils from './utils'

Vue.prototype.$utils = utils
Vue.config.productionTip = false;

const wx = window.wx;
Vue.prototype.$wx = wx;

Vue.use(Antd);
// Vue.use($);

new Vue({
    render: h => h(App),
    router,
    data: function() {
        return {
            baseUrl: "/scrm_pc",
            // url: "https://jieb.jctmj.cn/index.php",
            url: "https://scrm.jcjbqj.com/index.php",////
            // url: "http://jbs.scrm.cn/index.php",
            // url: "http://scrmapp.jctmj.cn/index.php",

            
           
            // url: "http://local.jb.net/index.php"
        }
    },
    metods: {

    }
}).$mount('#app')